.doha span:nth-child(1) {
   font-family: "Circle Monogram Left";
}
.doha span:nth-child(2) {
   font-family: "Circle Monogram Mid";
}
.doha span:nth-child(3) {
   font-family: "Circle Monogram Right";
}

.marrakech {
   font-family: "NotoSerif";
}
.marrakech .towel, .marrakech .robe {
   display: flex;
   align-items: center;
}
.marrakech .large {
   font-size: 120%;
}

.oslo {
   font-family: "Staatliches";
}
.oslo .message1:before, .oslo .message1:after {
   content: "•";
}
.oslo .message2::before, .oslo .message3::before {
   content: "•";
}

.cambridge {
   font-family: "academy";
}

.paris {
   font-family: "Leckerli";
}
.paris .message6 {
   font-size: 80%;
}
.paris .message7 {
   font-size: 70%;
}
.paris .message8 {
   font-size: 60%;
}

.seoul {
   font-family: "Cooper";
}
.seoul .message6 {
   font-size: 80%;
}
.seoul .message7 {
   font-size: 70%;
}
.seoul .message8 {
   font-size: 60%;
}

.miami {
   font-family: "Big John";
}
.miami .message6 {
   font-size: 70%;
}
.miami .message7 {
   font-size: 65%;
}
.miami .message8 {
   font-size: 60%;
}

.berlin {
   font-family: "Berlin Sans";
}
.berlin .message6 {
   font-size: 80%;
}
.berlin .message7 {
   font-size: 70%;
}
.berlin .message8 {
   font-size: 60%;
}

.doha_size {
   font-size: 340%;
}

.marrakech_size {
   font-size: 150%;
}

.oslo_size {
   font-size: 150%;
}

.cambridge_size {
   font-size: 250%;
}

.paris_size {
   font-size: 150%;
}

.seoul_size {
   font-size: 150%;
}

.miami_size {
   font-size: 150%;
}

.berlin_size {
   font-size: 150%;
}

.product_preview {
   border: 1px solid #fff;
   border-radius: 5px;
   overflow: hidden;
   position: absolute;
   right: 20px;
   bottom: 20px;
   background-color: #fff;
   padding: 2px;
   z-index: 100;
   will-change: opacity;
   transition: opacity 0.3s ease;
   opacity: 1;
}